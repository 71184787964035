body {
    margin: 0;
    padding: 0;
    font-family: 'Rajdhani', -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif;
    /* font-family: -apple-system, BlinkMacSystemFont, 'Segoe UI', 'Roboto', 'Oxygen', 'Ubuntu',
        'Cantarell', 'Fira Sans', 'Droid Sans', 'Helvetica Neue', sans-serif; */
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
}

code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
}

 :root {
    --wpa-dark-blue: #002B5C;
    --wpa-green: #007B85;
    --wpa-yellow: #FFE512;
}

div {
    background-color: var(--wpa-green);
    color: white;
}

h1, h2, h3, h4, h5, h6, p, span, tr {
    font-family: 'Rajdhani';
    color: white;
}

a {
    color: var(--wpa-yellow);
}

a * {
    color: var(--wpa-yellow);
}

a:hover {
    color: #FFE512;
    text-decoration: none;
}

.myNav a {
    color: white;
}

.myNav a:hover {
    color: var(--wpa-yellow);
    text-decoration: none;
}

.eventTabPicker a.active {
    color: #002B5C;
    background-color: #FFE512;
    border-radius: 10px;
    font-weight: bolder;
}

.eventTabPicker a {
    color: var(--wpa-yellow);
    border: solid 1px var(--wpa-dark-blue);   
    border-radius: 10px;
}

.eventTabPicker a:hover {
    color: var(--wpa-dark-blue);
    background-color: var(--wpa-yellow);
    border: solid 1px var(--wpa-dark-blue);   
    border-radius: 10px;
}

a.active {
    color: #002B5C;
    background-color: #FFE512;
    font-weight: bold;
}

.dropdown button {
    color: #002B5C;
    background-color: #FFE512;
    border-color: #002B5C;
}

.dropdown button:hover {
    color: #002B5C;
    background-color: white;
}

th {
    color: white;
    font-weight: bolder;
}

.dropdown-menu {
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
}

.matchContainer {
    background-color: #002B5C;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border: solid 1px #002B5C;   
    border-radius: 10px;
    padding-top: 5px;
    padding-left: 10px;
    padding-right: 10px;
    padding-bottom: 5px;
}

.matchContainer div {
    background-color: #002B5C;
}

.matchElement {
    border-bottom: 1px solid lightslategrey;
}

.matchElement:last-child {
    background-color: white;
    border-bottom: 0px solid lightslategrey;
}

.sponsorBanner {
    padding-top: 10px;
    margin-bottom: 10px;

    background-color: white;
    box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.2);
    border-radius: 10px;    
}

.sponsorBanner div  {
    background-color: white;
}

.sponsorBanner .carousel-indicators li {
    margin-top: 20px;
    color: gray;
    background-color: #007B85;
} 

.matchContainer .list-group-item {
    background-color: var(--wpa-green);
}

.matchContainer .list-group-item div {
    background-color: var(--wpa-green);
}

.matchContainer .list-group-item.active {
    background-color: var(--wpa-yellow);
    border-color: var(--wpa-yellow);
    color: var(--wpa-dark-blue)
 }

.myListView .list-group-item {
    background-color: var(--wpa-green);
}

.myListView .list-group-item.active {
    background-color: var(--wpa-yellow);
    border-color: var(--wpa-dark-blue);
    color: var(--wpa-dark-blue);
    font-weight: bold;
 }

 .myListView * :hover * {
    color: var(--wpa-yellow);
 }

 .myListView :hover {
    color: var(--wpa-yellow);
 }

 .matchContainer .list-group {
    border-radius: 10px;  
 }

 a:hover .disclosureIndicator {
    text-decoration: underline;
    color: var(--wpa-yellow);
 }

 .card {
    background-color: var(--wpa-dark-blue);
 }

 .card-header div {
    background-color: var(--wpa-dark-blue);
 }

 .card .list-group-item {
    background-color: var(--wpa-green);
 }

 .pageHeader a span {
    color: var(--wpa-yellow);
 }

.upcomingMatch {
    padding-left: 20px;
    padding-right: 20px;
    min-height: 50px;
}

.upcomingMatchContainer li {
    border: solid 1px rgba(0,0,0,0.1);      
}

.upcomingMatch a:hover * {
    color: var(--wpa-yellow);
    text-decoration: none;
 }

 .upcomingMatch a * {
    color: white;  
 }

 button div {
    background-color: inherit;
 }

 .statEntry .btn-outline-primary {
    color: white;  
    border-color: white;
 }

 .matchDetailPlayerSummary {
    background-color: var(--wpa-green);
 }

 .dateRangePicker div {
    background-color: inherit;
 }

.eventMetadata .card-footer {
    background-color: var(--wpa-green);
}
